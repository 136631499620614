interface MapDistanceInputProps {
    fromLat: number;
    fromLng: number;
    toLat: number;
    toLng: number
}
const mapDistance = (input: MapDistanceInputProps): number => {
    const { fromLat, fromLng, toLat, toLng } = input
    const R = 6371e3; // metres
    const φ1 = fromLat * Math.PI / 180; // φ, λ in radians
    const φ2 = toLat * Math.PI / 180;
    const Δφ = (toLat - fromLat) * Math.PI / 180;
    const Δλ = (toLng - fromLng) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d;
}
export default mapDistance;