import { PaginationProps } from 'antd';
import { useState } from 'react';
// import { PaginationProps } from '../../.fetch';

interface StateProps {
    loading: boolean;
    successed: boolean;
    error: boolean;
    message?: string;
}

declare type SetDataInputCallback = (list: any[]) => any[]
declare type SetDataInputProps = any[] | SetDataInputCallback;

declare type SetSelectedRowInputCallback = (rows: any[]) => any[]
declare type SetSelectedRowProps = any[] | SetSelectedRowInputCallback;

declare type SetPaginationCallback = (pagination: PaginationProps) => PaginationProps;
declare type SetPaginationProps = PaginationProps | SetPaginationCallback;

export interface UseListProps extends StateProps {
    getData: () => any[];
    setData: (data: SetDataInputProps) => void;

    getSelectedRows: () => any[];
    setSelectedRows: (rows: SetSelectedRowProps) => void;

    checkAll: () => void;
    isCheckAll: boolean;
    unCheckAll: () => void;

    getPagination: () => PaginationProps | undefined;
    setPagination: (pagination: PaginationProps) => void;

    onAdd: (item: any) => void;
    onLoading: () => void;
    onSuccess: () => void;
    onError: (message?: string) => void;

    getQ: () => string | undefined;
    setQ: (q: string | undefined) => void;
}

const useList = (): UseListProps => {
    const [rows, setRows] = useState<any[]>([]);
    const [pagination, setPagination] = useState<PaginationProps | undefined>(undefined);
    const [data, setData] = useState<any[]>([]);
    const [q, setQ] = useState<string | undefined>(undefined);
    const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
    const [state, setState] = useState<StateProps>({
        loading: false,
        successed: false,
        error: false,
        message: undefined,
    })

    return {
        ...state,

        getData: () => data,
        getSelectedRows: () => rows,
        getPagination: () => pagination,

        setSelectedRows: (rows) => setRows(rows),
        setPagination: (pagination) => setPagination(pagination),
        setData: (data) => {
            setData(data);
        },

        onAdd: (item) => setData(data => [item, ...data]),
        onLoading: () => {
            setState({
                loading: true,
                successed: false,
                error: false,
                message: "Vui lòng chờ..."
            })
        },
        onSuccess: () => {
            setState({
                loading: false,
                successed: true,
                error: false,
                message: undefined
            })
        },
        onError: (message) => {
            setState({
                loading: false,
                successed: false,
                error: true,
                message
            })
        },
        getQ: () => q,
        setQ: (q) => setQ(q),
        isCheckAll,
        checkAll: () => setIsCheckAll(true),
        unCheckAll: () => setIsCheckAll(false)
    };
}

export default useList;