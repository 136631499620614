import React, { FC, ReactNode } from "react";
import CMarkerClusterGroup from "./MarkerClusterGroup";
interface MarkerClusterGroupProps {
    children: ReactNode
}
const MarkerClusterGroup: FC<MarkerClusterGroupProps> = ({ children }) => {
    return <CMarkerClusterGroup>
        {children}
    </CMarkerClusterGroup>
}

export default MarkerClusterGroup;