import { ActionProps } from "..";

export interface ItemProps {
    giaTri: string;
    id: string;
    isDelete: string,
    tenThamSo: string
}

const stateDefault: ItemProps[] = [
]
const EDIT_CAUHINH = (list: ItemProps[], edit: ItemProps): ItemProps[] => {
    return list.map(value => {
        return value.id === edit.id ? { ...value, ...edit } : value
    })
}


const cauHinh = (state: ItemProps[] = stateDefault, action: ActionProps): ItemProps[] => {
    const type = action.type;
    switch (type) {
        case 'CAUHINH_LOAD':
            const cauhinh = action.payload as ItemProps[]
            return cauhinh;
        case 'CREATE_CAUHINH':
            const create = action.payload as ItemProps;
            return [...(state || []), create]
        case "EDIT_CAUHINH":
            const edit = action.payload as ItemProps;
            return EDIT_CAUHINH(state, edit)
        default:
            return state;
    }



}

export default cauHinh;