import React, { Fragment, lazy, Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import vi_VN from "antd/lib/locale-provider/vi_VN";
import moment from "moment";
import { BrowserRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { createStore } from "./reducers";
import indexReducer from "./reducers";
import { AntConfigProvider, AntSpin, useBrowserContextCommunication, AntRow, AntCol, AntCurrency, AntForm, AntButton, CommonLoading } from './common';
import 'moment/locale/vi';
import "./.static/scss/size.scss";
import "./.static/scss/theme-menu.scss";
import "./.static/scss/theme-header.scss";
import "./.static/scss/theme.scss";
import "./.static/scss/form.scss";
import "./.static/scss/graphcms.scss";
import "./.static/scss/line-clamp.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./.static/css/style/animate.min.css";
import "./.static/css/style/antd-custom.css";
import "./.static/css/style/antd.min.css";
import "./.static/css/style/drop-preview.css";
import "./.static/css/style/file.css";
import "./.static/css/style/googleapis.min.css";
import "./.static/css/style/index.css";
import "./.static/css/style/mobile.css";
import "./.static/css/style/page-builder.css";
import "./.static/css/style/prism.css";
import "./.static/css/style/product.css";
import "./.static/css/style/react-grid-layout.css";
import "./.static/css/style/react-image-crop.css";
import "./.static/css/style/react-perfect-scrollbar.css";
import "./.static/css/style/style.css";
import "./.static/css/style/vietmap-gl.min.css";

import { getAllLocales } from "./.api/cau-hinh";
import { checkToken } from './.api/auth';
import { parse, stringify } from 'qs';
import { getAllPage } from './.api/page';
moment.locale('vi');
const View = lazy(() => import(`./view`));
const ResetPassword = lazy(() => import(`./view/reset-password`))
const ViewMobile = lazy(() => import(`./view.mobile`))
const LoginWidth = lazy(() => import(`./view/login-width`))
const AccountActive = lazy(() => import("./view/account-active"));

const qs = parse(window.location.search, { ignoreQueryPrefix: true });
const device_id = qs.device_id;
const device_os = qs.device_os;

if (device_id) {
    localStorage.setItem("device_id", device_id);
}
if (device_os) {
    localStorage.setItem("device_os", device_os);
}

const RouterRender = () => {
    const dispatch = useDispatch();
    const [communicationState] = useBrowserContextCommunication("session");
    const auth = useSelector(state => state.auth);
    const { search } = useLocation();
    useEffect(() => {
        if (communicationState.lastMessage) {
            const { data, callback } = communicationState.lastMessage;
            if (data.uuid !== auth.uuid) {
                dispatch({ type: "AUTH_CHANGED", payload: data })
                callback && callback();
            }
        }
    }, [communicationState.lastMessage, dispatch, auth.uuid])

    useEffect(() => {
        const getData = async () => {
            const res = await getAllLocales();
            dispatch({
                type: "LOCAL_LOAD",
                payload: res || []
            })

        }
        getData();
    }, [])

    return <Switch>
        <Route path="/accout-active" exact>
            <Suspense fallback={<CommonLoading />}>
                <AccountActive />
            </Suspense>
        </Route>
        <Route path="/login/sso/:login_width" exact>
            <Suspense fallback={<CommonLoading />}>
                <LoginWidth />
            </Suspense>
        </Route>
        <Route path="/reset-password/:token" exact>
            <Suspense fallback={<CommonLoading />}>
                <ResetPassword />
            </Suspense>
        </Route>
        <Route path="/" exact>
            <Redirect to={{
                pathname: `/trang-chu.html`,
                search
            }} />
        </Route>
        <Route path={["/:lang"]}>
            <Suspense fallback={<CommonLoading />}>
                <View />
            </Suspense>
        </Route>
    </Switch >
}

const RouterMobileRender = () => {
    const dispatch = useDispatch();
    const [communicationState] = useBrowserContextCommunication("session");
    const auth = useSelector(state => state.auth);
    const { search } = useLocation();
    useEffect(() => {
        if (communicationState.lastMessage) {
            const { data, callback } = communicationState.lastMessage;
            if (data.uuid !== auth.uuid) {
                dispatch({ type: "AUTH_CHANGED", payload: data })
                callback && callback();
            }
        }
    }, [communicationState.lastMessage, dispatch, auth.uuid])

    useEffect(() => {
        const getData = async () => {
            const res = await getAllLocales();
            dispatch({
                type: "LOCAL_LOAD",
                payload: res || []
            })

        }
        getData();
    }, [])

    return <Switch>
        <Route path="/" exact>
            <Redirect to={{
                pathname: `/trang-chu.html`,
                search
            }} />
        </Route>
        <Route>
            <Suspense fallback={<CommonLoading />}>
                <ViewMobile />
            </Suspense>
        </Route>
    </Switch >
}

const CheckInvalidToken = ({
    children,
    isMobile
}) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [checked, setChecked] = useState(auth.accountCurrent ? true : false);
    const [communicationState, postMessage] = useBrowserContextCommunication("session");
    const getPages = async () => {
        const pages = await getAllPage();
        dispatch({
            type: "PAGE_LOAD_DATA",
            payload: pages
        })
    }
    useEffect(() => {
        getPages();
        const c = async () => {
            const res = await checkToken();
            if (!res.error) {
                dispatch({
                    type: "LOGIN_CHECKED",
                    payload: { accountCurrent: res },
                    callback: data => postMessage(data)
                });
            }
            else {
                dispatch({
                    type: "LOGOUT",
                    callback: data => postMessage(data)
                });
            }
            setChecked(true);
        }
        !auth.accountCurrent && c();

    }, [])

    return checked ?
        children :
        <Fragment>
            {
                isMobile ?
                    <CommonLoading /> :
                    <AntSpin
                        spinning
                        indicator={<CommonLoading />}
                        style={{ height: "100vh" }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: document.body.innerHTML }} />
                    </AntSpin>
            }
        </Fragment>;
}


ReactDOM.render(<Provider store={createStore(indexReducer)} key="AUTH">
    <AntConfigProvider locale={vi_VN}>
        {/* <TTS /> */}
        <BrowserRouter>
            <CheckInvalidToken isMobile>
                {window.navigator.userAgent.includes(" unitech ") ?
                    <RouterMobileRender /> :
                    <RouterRender />
                }
            </CheckInvalidToken>
        </BrowserRouter>
    </AntConfigProvider>
</Provider >, document.getElementById('root')
);


reportWebVitals();
