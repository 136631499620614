import { combineReducers, Reducer, CombinedState } from "redux";
import dataFormBuilders, {
    formBuilders,
    formBuilder,
    formFields,
    fileSelected,
    map,
    dataRef,
    DataFormBuilderProps as DataFormBuilderStateProps,
    FormWithConfigProps as FormWithConfigStateProps,
    FormBuilderProps as FormBuilderStateProps,
    FormFieldProps as FormFieldStateProps,
    FileSelectedProps as FileSelectedStateProps,
    MapProps as MapStateProps,
    DataRefProps as DataRefStateProps,
} from "./dataformbuilders";

export default combineReducers({
    dataFormBuilders,
    formBuilders,
    formBuilder,
    formFields,
    fileSelected,
    map,
    dataRef,
}) as Reducer<CombinedState<DataFormBuilderState>>;

export interface DataFormBuilderState {
    dataFormBuilders: DataFormBuilderStateProps[];
    formBuilders: FormWithConfigStateProps;
    formBuilder: FormBuilderStateProps;
    formFields: FormFieldStateProps[];
    fileSelected: FileSelectedStateProps;
    map: MapStateProps[];
    dataRef: DataRefStateProps[];
}
