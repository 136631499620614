import { AntModal, removeAuth, TokenProps } from "../common";
import { AbortController as AbortControllerProps } from "abort-controller";
import React, { Fragment, ReactNode } from "react";
import { Link } from "react-router-dom";

export interface LoginDataProps {
	username: string;
	password: string
	remember?: boolean;
}
export const HOST_NAME = process.env.REACT_APP_API_HOST;

export const urlLogin = "/dang-nhap.html";
export const urlLoginExpiredToken = `${urlLogin}?expired_token=1`

export interface PaginationProps {
	currentPage: number;
	pageSize: number;
	total?: number;
}
export interface ResponseProps {
	error: boolean;
	statusCode: number;
	status?: boolean;
	result?: any;
	msg?: string;
	pagination?: PaginationProps,
	[key: string]: any
}
export interface ResponseDownloadProps extends ResponseProps {
	header?: Headers;
	blob?: () => Blob;
}

export interface APIProps {
	url?: string;
	data?: any;
	formData?: FormData;
	auth?: boolean;
	authValue?: string;
	showLoading?: boolean;
	token?: string,
	controller?: AbortControllerProps,
	callback?: (res: ResponseProps) => void;
	showMsg?: boolean;
	loadingMessage?: ReactNode;
	hostName?: string;
	isJsonParse?: boolean;
	headerOptions?: HeadersInit
}

export interface GetProps extends APIProps {
	showModal401?: boolean;
}
export interface PostProps extends APIProps { }
export interface PutProps extends APIProps { }
export interface DelProps extends APIProps { }
export interface DownloadProps extends GetProps {
	responseType?: "download" | "fileInfo";
	method?: string;
}

export interface ResponseLoginProps extends ResponseProps {
	token?: TokenProps;
}

export interface LoginPostProps extends Omit<APIProps, "data"> {
	data: LoginDataProps
}
export interface RefreshPostProps extends Omit<APIProps, "callback"> {
	callback: (props: GetProps | PostProps | PutProps | DelProps) => Promise<any>
}


export const startLoad = (loadingMessage?: ReactNode) => {
	const _loading = document.body.getAttribute("loading");
	const loading: number = _loading && !isNaN(parseInt(_loading)) ? parseInt(_loading, 0) + 1 : 1;
	document.body.setAttribute("loading", `${loading}`);
}

export const endLoad = () => {
	const _loading = document.body.getAttribute("loading");
	const loading: number = _loading && !isNaN(parseInt(_loading)) && parseInt(_loading) > 0 ? parseInt(_loading, 0) - 1 : 0;
	document.body.setAttribute("loading", `${loading}`);
}

export { default as get } from "./.get";
export { default as post } from "./.post";
export { default as put } from "./.put";
export { default as del } from "./.del";
export { default as login } from "./.login";
export { default as download } from "./.download";

export const e401 = async (data: any): Promise<ResponseProps> => {
	const modalShowing = document.body.getAttribute("modal_showing");
	if (modalShowing === "1") {
		return data;
	}
	document.body.setAttribute("modal_showing", "1");
	return new Promise(resolve => {
		AntModal.warning({
			title: "Phiên làm việc đã kết thúc",
			content: "Vui lòng đăng nhập lại để tiếp tục sử dụng",
			onOk: () => {
				removeAuth();
				resolve(data);
				location.href = "/?login=true";
			},
			onCancel: () => {
				removeAuth();
				resolve(data);
			},
			okText: <Fragment>
				<i className="fa fa-sign-in mr-5" />Đăng nhập
			</Fragment>
		})
	})
}