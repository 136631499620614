import React, { Fragment } from "react";
import { CheckList } from 'antd-mobile'
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { dangKyThietBiRequest, huyDangKyThietBiRequest } from "../../.api/dang-ky-thiet-bi";

interface NotificationSetupProps {
    accountId: number;
    nhanThongBao?: boolean;
    chuongThongBao?: boolean;
    nhanThongBaoKhuyenMai?: boolean
}
const defaultSetup = {
    accountId: -1,
    nhanThongBao: false,
    chuongThongBao: false,
    nhanThongBaoKhuyenMai: false
}

export const findSettingNotificationByUser = (userId?: number) => {
    const localStorageNotificationSetup = localStorage.getItem("mobile_notification_setup") || "[]";
    let notificationSetup: NotificationSetupProps[] = [defaultSetup]
    try {
        notificationSetup = JSON.parse(localStorageNotificationSetup) as NotificationSetupProps[];
    }
    catch (e) { }
    return {
        defaultValue: Object.entries(notificationSetup.find(item => item.accountId === userId) || defaultSetup).filter(item => item[1] === true).map(item => item[0]),
        notificationSetup,
        configured: notificationSetup.find(item => item.accountId === userId) ? true : false
    };
}
const MSettingNotification = () => {
    const accountCurrent = useSelector((state: RootState) => state.auth.accountCurrent);
    return <Fragment>
        <CheckList
            key={accountCurrent?.id || "-1"}
            multiple
            defaultValue={findSettingNotificationByUser(accountCurrent?.id).defaultValue}
            onChange={(val) => {
                if (val.includes("nhanThongBao")) {
                    dangKyThietBiRequest({
                        username: accountCurrent?.name,
                        deviceId: localStorage.getItem("device_id"),
                        deviceOS: localStorage.getItem("device_os")
                    })
                }
                else {
                    accountCurrent?.name && huyDangKyThietBiRequest(accountCurrent.name)
                }
                const setups = findSettingNotificationByUser(accountCurrent?.id).notificationSetup;
                const res: { [key: string]: boolean } = {};
                val.map(v => { res[v] = true })
                const result = setups.findIndex(s => s.accountId === (accountCurrent?.id || -1)) >= 0 ?
                    setups.map(item => item.accountId === (accountCurrent?.id || -1) ? { accountId: accountCurrent?.id || -1, ...res } : item) :
                    [...setups, { ...res, accountId: accountCurrent?.id || -1 }]
                localStorage.setItem("mobile_notification_setup", JSON.stringify(result));
            }}
        >
            <CheckList.Item value="nhanThongBao">Cho phép nhận thông báo</CheckList.Item>
            <CheckList.Item value='chuongThongBao'>Tắt chuông thông báo</CheckList.Item>

            <CheckList.Item value='nhanThongBaoKhuyenMai'>Nhận thông báo khuyến mãi</CheckList.Item>
        </CheckList>
    </Fragment >
}

export default MSettingNotification;