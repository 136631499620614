import { AntMessage } from "../antd";

const copyToClipboard = (text: string) => {
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    AntMessage.success({
        key: "COPY_TO_CLIPBOARD",
        content: "Đã sao chép vào bộ nhớ tạm"
    });
}

export default copyToClipboard;