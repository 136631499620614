import React, { FC, ReactNode, useEffect, useState } from "react";
import styled, { CSSProperties, keyframes } from 'styled-components';
import * as reactAnimations from "react-animations";

const CommonAnimations: FC<IProps> = ({ children, animationDuration = ".5s", animationType, style }) => {
    const [Component, setComponent] = useState<any>(null);
    useEffect(() => {
        let Component: any;
        if (animationType) {
            const t: reactAnimations.Animation = reactAnimations[animationType];
            Component = styled.div`
                animation: .5s ${keyframes`${t}`};
            `;
        }
        else {
            Component = styled.div``;
        }
        setComponent(Component)
    }, [animationDuration, animationType])

    return Component ? <Component
        style={{ ...style, animationDuration }}
    >
        {children}
    </Component> : null
}

export default CommonAnimations;


export declare type AnimationType = "bounceOut" |
    "bounce" |
    "bounceIn" |
    "bounceInDown" |
    "bounceInLeft" |
    "bounceInRight" |
    "bounceInUp" |
    "bounceOutDown" |
    "bounceOutLeft" |
    "bounceOutRight" |
    "bounceOutUp" |
    "fadeIn" |
    "fadeInDown" |
    "fadeInDownBig" |
    "fadeInLeft" |
    "fadeInLeftBig" |
    "fadeInRight" |
    "fadeInRightBig" |
    "fadeInUp" |
    "fadeInUpBig" |
    "fadeOut" |
    "fadeOutDown" |
    "fadeOutDownBig" |
    "fadeOutLeft" |
    "fadeOutLeftBig" |
    "fadeOutRight" |
    "fadeOutRightBig" |
    "fadeOutUp" |
    "fadeOutUpBig" |
    "flash" |
    "flip" |
    "flipInX" |
    "flipInY" |
    "flipOutX" |
    "flipOutY" |
    "headShake" |
    "hinge" |
    "jello" |
    "lightSpeedIn" |
    "lightSpeedOut" |
    "pulse" |
    "rollIn" |
    "rollOut" |
    "rotateIn" |
    "rotateInDownLeft" |
    "rotateInDownRight" |
    "rotateInUpLeft" |
    "rotateInUpRight" |
    "rotateOut" |
    "rotateOutDownLeft" |
    "rotateOutDownRight" |
    "rotateOutUpLeft" |
    "rotateOutUpRight" |
    "rubberBand" |
    "shake" |
    "slideInDown" |
    "slideInLeft" |
    "slideInRight" |
    "slideInUp" |
    "slideOutDown" |
    "slideOutLeft" |
    "slideOutRight" |
    "slideOutUp" |
    "swing" |
    "tada" |
    "wobble" |
    "zoomIn" |
    "zoomInDown" |
    "zoomInLeft" |
    "zoomInRight" |
    "zoomInUp" |
    "zoomOut" |
    "zoomOutDown" |
    "zoomOutLeft" |
    "zoomOutRight" |
    "zoomOutUp";
interface IProps {
    children: ReactNode;
    animationDuration?: string;
    animationType?: AnimationType;
    style?: CSSProperties

}