import React, { Fragment } from "react";
import { getAuth, removeAuth, setAuth, TokenProps, AntModal } from "../common"
import { stringify } from "qs"
import { startLoad, HOST_NAME, endLoad, RefreshPostProps, ResponseLoginProps, urlLogin } from ".";

export default async function <T = ResponseLoginProps>({ hostName, showLoading, callback }: RefreshPostProps): Promise<T> {
    const auth = getAuth();
    const {
        remember,
        refresh_token
    } = auth
    showLoading !== false && startLoad()
    const response = await fetch(`${hostName || HOST_NAME}/uaa/oauth/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': "Basic YnJvd3Nlcjo="
        },
        body: stringify({
            grant_type: "refresh_token",
            refresh_token
        }),
    })
    showLoading !== false && endLoad();
    const _data = await response.json();
    switch (response.status) {
        case 200:
            const _res: TokenProps = {
                ..._data,
                remember,
                token: `${_data.token_type} ${_data.access_token}`
            };
            setAuth(_res);
            return callback({ token: _res.token });
        default:
            return new Promise(resolve => {
                AntModal.warning({
                    title: "Phiên làm việc đã kết thúc",
                    content: "Vui lòng đăng nhập lại để tiếp tục sử dụng",
                    onOk: () => {
                        removeAuth();
                        resolve({ error: true, statusCode: response.status, ..._data })
                    },
                    onCancel: () => {
                        removeAuth();
                        resolve({ error: true, statusCode: response.status, ..._data })
                    },
                    okText: <Fragment><i className="fa fa-sign-in mr-5" />Đăng nhập</Fragment>
                })
            })
    }
}