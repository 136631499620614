import { ActionProps } from "..";
interface DataProps {
    name: string;
    id: string;
    default: boolean,
}
export interface ItemProps {
    loaded: boolean,
    data: DataProps[]
}

const stateDefault: ItemProps = {
    loaded: false,
    data: []
}

const localizations = (state: ItemProps = stateDefault, action: ActionProps): ItemProps => {
    const type = action.type;
    switch (type) {
        case 'LOCAL_LOAD':
            const data = action.payload as DataProps[];
            return {
                loaded: true,
                data
            };
        default:
            return state;
    }
}

export default localizations;