import React, { FC, useState, useEffect } from "react";
import { CommonForm, AntCard, AntButton, AntMessage } from "../../../common";
import { useForm } from "antd/lib/form/Form";
import { sendMailActiveAccount } from "../../../.api/auth";
import { Button } from "antd";
import { MailOutlined } from "@ant-design/icons";

interface SendMail {}

const SendMailActive = () => {
  const [form] = useForm();
  const [checkForm, setCheckForm] = useState(false);
  const [checkShowMsg, setCheckShowMsg] = useState(false);
  const [checkError, setCheckError] = useState(true);
  const [modal, setModal] = useState({
    visible: false,
    onShow: () => setModal((m) => ({ ...m, visible: true })),
    onCancel: () => setModal((m) => ({ ...m, visible: false })),
  });

  const sendMail = async (data: string) => {
    const res = await sendMailActiveAccount(data);
    if (res.status && res.statusCode === 200) {
      setCheckShowMsg(true);
      setCheckForm(false);
      setCheckError(false);
    } else {
      AntMessage.error(res.msg);
    }
  };
  const onFinish = (values: any) => {
    sendMail(values.mail);
  };

  const onHandleSend = () => {
    setCheckForm(true);
    setCheckError(false);
  };
  return (
    <div>
      <h6>
        {checkShowMsg
          ? "Link kích hoạt đã được gửi đến gmail của bạn!"
          : "Tài khoản chưa được kích hoạt"}
      </h6>

      {checkError && (
        <Button
          style={{
            margin: "10px",
            fontSize: "15px",
            border: "0px",
            background: "#fff",
          }}
          onClick={() => onHandleSend()}
        >
          Click vào đây để nhận lại mã kích hoạt
        </Button>
      )}

      {checkForm && (
        <AntCard
          actions={[
            <AntButton type="primary" onClick={() => form.submit()}>
              Gửi
            </AntButton>,
          ]}
        >
          <CommonForm
            onFinish={onFinish}
            form={form}
            layout="vertical"
            data={[
              {
                col: 24,
                name: "mail",
                type: "email",
                formItemProps: {
                  label: "Tài khoản gmail",
                  rules: [{ required: true, message: "Vui lòng nhập mail" }],
                },
                fieldProps: {
                  size: "large",
                  autoComplete: "new-password",
                  prefix: <MailOutlined />,
                },
              },
            ]}
          />
        </AntCard>
      )}
    </div>
  );
};

export default SendMailActive;
