import React, { FC, useEffect, useState, Fragment } from "react";
import RouterMachine from "./router-machine";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { Popup as AntMobilePopup, FloatingPanel } from "antd-mobile"
export interface InstructionProps {
    direction: "";
    distance: number;
    index: number;
    mode: string;
    modifier: string;
    type: "Head"
    | "Left"
    | "Right"
    | "Continue"
    | "SlightRight"
    | "SlightLeft"
    | "Merge"
    | "Straight"
    | "Roundabout"
    | "SharpRight"
    | "SharpLeft"
    | "Fork"
    | "EndOfRoad"
    | "DestinationReached";
    road: string;
    text: string;
}
export interface RouteSelectedCallbackInputProps {
    summary: {
        totalDistance: number;
        totalTime: number;
    },
    instructions: Array<InstructionProps>
}
interface RouterMachineProps {
    from: [number, number] | [number, number, string] | "current";
    to: [number, number] | [number, number, string];
    showCurrent?: boolean;
    startCallback?: (e: any) => void;
    successCallback?: (e: any) => void;
    errorCallback?: (e: any) => void;
    routeSelectedCallback?: (data: RouteSelectedCallbackInputProps) => void;
    showMapGuide?: boolean;
}
const CommonRouterMachine: FC<RouterMachineProps> = ({
    from,
    to,
    showCurrent,
    startCallback,
    successCallback,
    errorCallback,
    routeSelectedCallback,
    showMapGuide
}) => {
    const currentLocation = useSelector((state: RootState) => state.config.currentLocation);
    const [start, setStart] = useState<boolean>(false)
    const [selectedRouter, setSelectedRouter] = useState<RouteSelectedCallbackInputProps | null>(null);

    useEffect(() => {
        setStart(true);
    }, [from, to])
    return <Fragment>
        {showCurrent && currentLocation?.lat && currentLocation?.lng && <Marker position={[currentLocation.lat, currentLocation.lng]}>
            <Popup> Vị trí của tôi</Popup>
        </Marker>}
        {showMapGuide !== false && from && to && !start && <FloatingPanel anchors={[100, window.innerHeight * 0.4, window.innerHeight * 0.8]} style={{ zIndex: 99999 }}>
            <div>
                {selectedRouter?.summary.totalTime} ({selectedRouter?.summary.totalDistance} m)
            </div>
        </FloatingPanel>}
        <RouterMachine
            from={from}
            to={to}
            startCallback={(e: any) => {
                startCallback && startCallback(e)
            }}
            successCallback={(e: any) => {
                setStart(false);
                successCallback && successCallback(e)
            }}
            errorCallback={(e: any) => {
                setStart(false);
                errorCallback && errorCallback(e)
            }}
            routeSelectedCallback={(e: RouteSelectedCallbackInputProps) => {
                routeSelectedCallback && routeSelectedCallback(e)
                setSelectedRouter(e);
            }}
        />
    </Fragment>
}

export default CommonRouterMachine;