import { LoadingOutlined } from "@ant-design/icons";
import React, { Fragment, useEffect, useState } from "react";
// import LoadingBar from 'react-loading-bar';

let timeout = null;
const Loading = ({ show, color = "red" }) => {
	const [showSpin, setShowSpin] = useState(show);
	useEffect(() => {
		if (show) {
			timeout && clearTimeout(timeout);
			setShowSpin(true);
		}
		else {
			timeout = setTimeout(() => {
				setShowSpin(false);
			}, 400);
		}
		return () => {
			timeout && clearTimeout(timeout);
		}
	}, [show]);
	return <Fragment>
		<div className="loading-bar" style={{ color }}>
			{/* <LoadingBar
				show={show}
				color={color}
			/> */}
			{showSpin && <LoadingOutlined color={color} size={15} />}
		</div>
	</Fragment>
}

export default Loading;