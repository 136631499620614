import data from "../data";
import { ActionProps } from "./../"
export interface MapDataState {
   type?: string;
   groupId?: string;
   id?: number | string;
   data?: any;
   callback?: (data: any) => any
}

export interface ObjectMapDataState {
   type?: number;
   formId: number;
   dataToId: MapDataState[]
}

export interface ObjectPayload {
   type: number,
   formId: number;
   dataToId: any
}

const stateDefault: ObjectMapDataState[] = []
export default (state: ObjectMapDataState[] = stateDefault, action: ActionProps): ObjectMapDataState[] => {
   const type = action.type;
   const payload = action.payload as ObjectPayload;
   switch (type) {
      case "SET_MAP_DATA":
         if (state.length > 0 && state.findIndex(f => f.formId === payload.formId) !== -1) {
            return state.map(s => {
               return s?.formId === payload?.formId ? {
                  type: s.type,
                  formId: s.formId,
                  dataToId: s?.type === payload.type ? s.dataToId?.findIndex(f => f.type === payload.dataToId.type && f.id === payload.dataToId.id) === -1 ? [...s.dataToId, payload.dataToId] :
                     s.dataToId?.map(m => m.id === payload.dataToId.id && m.type === payload.dataToId.type ? payload.dataToId : m) : [payload.dataToId]
               } : s
            })
         } else {
            return [...state, { type: payload.type, formId: payload.formId, dataToId: [payload.dataToId] }]
         }
      case "CLEAR_DATA_GROUP_ID":
         return state.length > 0 ? state.map(s => {
            return s.type !== payload.type && s.formId == payload.formId ? { ...s, type: payload.type, dataToId: [] } : s
         }) : state
      default:
         return state;
   }
} 