import React, { FC, useEffect, useRef, useState } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import elementResizeEvent from "element-resize-event";
import { FCScrollBar } from './types';

const CommonScrollBar: FC<FCScrollBar> = ({ children, options, height, style, ...props }) => {
    const cRef = useRef<HTMLDivElement>(null)
    const [scrollRef, setScrollRef] = useState<any>(null);

    useEffect(() => {
        cRef && cRef.current && elementResizeEvent(cRef.current, () => {
            scrollRef && scrollRef.updateScroll();
        })
    }, [cRef, scrollRef]);

    return <ScrollBar
        style={{ maxHeight: height, ...style }}
        options={{ ...options, useBothWheelAxes: true, wheelSpeed: 0.3 }}
        ref={ref => setScrollRef(ref)}
        {...props}
    >
        <div ref={cRef}>
            {children}
        </div>
    </ScrollBar>
}
export default CommonScrollBar;