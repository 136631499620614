import { combineReducers, Reducer, CombinedState } from "redux";
import localizations, { ItemProps as LocalizationsProps } from "./localizations";
import cauHinh, { ItemProps as cauHinhProps } from "./cauHinh";
import currentLocation, { ItemProps as CurrentLocationProps } from "./currentLocation";

export default combineReducers({
    localizations,
    cauHinh,
    currentLocation
}) as Reducer<CombinedState<ConfigProps>>;

export interface ConfigProps {
    localizations: LocalizationsProps,
    cauHinh: cauHinhProps[],
    currentLocation: CurrentLocationProps | null
}