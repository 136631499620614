import { LoadingOutlined } from "@ant-design/icons";
import { Alert, AlertProps } from "antd";
import React, { FC } from "react";

interface AntAlertProps extends Omit<AlertProps, "type"> {
    type?: 'success' | 'info' | 'warning' | 'error' | "loading";
}
const AntAlert: FC<AntAlertProps> = ({ type, ...props }) => {
    switch (type) {
        case "loading":
            return <Alert type="info" icon={<LoadingOutlined />} message="Vui lòng chờ" {...props} />
        default:
            return <Alert type={type} {...props} />
    }
}

export default AntAlert;