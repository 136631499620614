import { Attributes } from "react";

export default (elm: HTMLElement, callback: (attributesChanged?: Attributes) => void): void => {
	const observer = new MutationObserver((mutations) => {
		mutations.forEach((mutation) => {
			if (mutation.type === "attributes") {
				callback(elm.attributes as Attributes);
			}
			return;
		});
	});
	observer.observe(elm, { attributes: true });
}