import { ActionProps } from "./../"
export interface DataState {
    type: string;
    groupId?: string;
    id?: number | string;
    data?: any;
    pagination?: any
    callback?: (data: any) => any
}
const stateDefault: DataState[] = []

export default (state: DataState[] = stateDefault, action: ActionProps): DataState[] => {
    const type = action.type;
    const payload = action.payload as DataState;
    switch (type) {
        case "SET_DATA":
            return state.findIndex(s => s.type === payload.type && s.id === payload.id) === -1 ?
                [...state, payload] :
                state.map(s => s.id === payload.id && s.type === payload.type ? payload : s)
        case "SET_GROUP_DATA":
            const groupData = state.filter(item => item.groupId === payload.groupId && item.type === payload.type);
            return state.map(s => {
                if (s.groupId === payload.groupId && s.type === payload.type) {
                    const _data = payload.data || (payload.callback && payload.callback(groupData));
                    const d = _data.find((item: any) => item.id === s.id)
                    return d || s;
                }
                return s;
            })
        case "CLEAR_DATA":
            return state.filter(s => !(s.type === payload.type && s.id === payload.id));
        case "CLEAR_DATA_GROUP_ID":
            return state.filter(s => !(s.type === payload.type && s.groupId === payload.groupId));
        default:
            return state;
    }
} 