import AbortController from "abort-controller";
import { AntMessage, setAcc, setAuth, TokenProps } from "./../common";
import { get, login as fetchLogin, post, put } from "./../.fetch"
import { dangKyThietBiRequest } from "./dang-ky-thiet-bi";
import { findSettingNotificationByUser } from "../view.mobile/setting/notification";
export interface LoginResponseProps {
    error?: boolean;
    token?: TokenProps | null;
    accountCurrent?: any
};
export interface LoginDataProps {
    username: string;
    password: string
    remember?: boolean;
}
export interface AccountProps {
    avatar?: string;
    id?: number;
    name: string;
    note?: string;
    fullName: string;
    phoneNumber?: string;
    desktopPhoneNumber?: string;
    email?: string;
    maDoanhNghiep?: string;
    typeAccount?: "DU_KHACH" | "BIEN_TAP" | "DOANH_NGHIEP";
    dateCreate?: string;
}

export declare type AccountType = "createAccountDuKhach" | "createAccountDoanhNghiep" | "createAccountBienTapVien"
interface InputProps {
    type: AccountType;
    data: {
        username: string;
        password: string;
        account: AccountProps
    }
}

export interface ResetPasswordProps {
    password: string;
    confirmPassword: string;
    token: string;
}

export interface ChangePasswordProps {
    password: string;
    newPassword: string;
    reTypeNewPassword: string;
    userName: string;
}
const dangKyThietBi = async (username: string) => {
    const deviceId = localStorage.getItem("device_id");
    const deviceOS = localStorage.getItem("device_os");
    const res = await dangKyThietBiRequest({
        username,
        deviceId,
        deviceOS
    })
}


export const activeAccount = async (value: string) => await post({ url: `/uaa/users/portal/public/activeAccount/${value}` });

export const sendMailActiveAccount = async (value: string) => await post({ url: `/accounts/api/public/sendMailActiveAccount?email=${value}` });

export const login = async ({ username, password, remember }: LoginDataProps, controller?: AbortController): Promise<LoginResponseProps> => {
    const res = await fetchLogin({
        data: {
            username,
            password,
            remember
        },
        controller
    });

    if (!res.error && res.token?.token) {
        const resAccountCurrent = await get({ url: `/accounts/current`, token: res.token?.token });
        if (!resAccountCurrent.error) {
            setAcc({
                fullName: resAccountCurrent.fullName,
                username
            });
            setAuth(res.token);
            if (!findSettingNotificationByUser(resAccountCurrent.id).configured) {
                const setups = findSettingNotificationByUser(resAccountCurrent.id).notificationSetup;
                const resCoonfig: { [key: string]: boolean } = {
                    ["nhanThongBao"]: true,
                    ["chuongThongBao"]: true,
                    ["nhanThongBaoKhuyenMai"]: true
                };
                const result = setups.findIndex(s => s.accountId === (resAccountCurrent.id || -1)) >= 0 ?
                    setups.map(item => item.accountId === (resAccountCurrent.id || -1) ? { accountId: resAccountCurrent.id || -1, ...resCoonfig } : item) :
                    [...setups, { ...resCoonfig, accountId: resAccountCurrent.id || -1 }]
                localStorage.setItem("mobile_notification_setup", JSON.stringify(result));
            }
            if (window.navigator.userAgent.includes(" unitech ") && findSettingNotificationByUser(resAccountCurrent.id).defaultValue.includes("nhanThongBao")) {
                dangKyThietBi(username);
            }
            return {
                error: res.error,
                token: res.token,
                accountCurrent: resAccountCurrent
            };
        }
    }
    return {
        error: res.error,
        token: res.token
    };
}

export const checkToken = async () => await get({ url: `/accounts/current`, showModal401: false })

export const accountUpdate = async (data: AccountProps) => await put({ url: `/accounts/`, data });

export const accountRegister = async (input: InputProps) => await post({ url: `/accounts/api/public/${input.type}`, data: input.data });

export const resetPassword = async (data: ResetPasswordProps) => await post({ url: `/uaa/users/portal/public/resetPassword`, data });

export const forgotPassword = async (email: string) => await post({ url: `/accounts/api/public/forgotPassword?email=${email}` });

export const changePassword = async (data: ChangePasswordProps) => await put({ url: `/uaa/users/current/changepass`, data })

/**
 * Login width
 */
export const loginWithFacebook = async (code: string): Promise<LoginResponseProps> => {
    const resFB = await get({ url: `/uaa/social/login/facebook`, data: { code } })
    if (!resFB.error && resFB.status !== false) {
        const token: TokenProps = {
            ...resFB,
            loginBy: "facebook",
            token: `${resFB.token_type} ${resFB.access_token}`
        }
        const resAccountCurrent = await get({ url: `/accounts/current`, token: token.token });
        if (!resAccountCurrent.error) {
            setAcc({
                fullName: resAccountCurrent.fullName,
                username: resAccountCurrent.name
            });
            setAuth(token);
            if (window.navigator.userAgent.includes(" unitech ")) {
                dangKyThietBi(resAccountCurrent.name);
            }
            return {
                error: false,
                token: token,
                accountCurrent: resAccountCurrent
            };
        }
    }
    return {
        error: true,
        token: null
    };
}

export const loginWithGoogle = async (code: string): Promise<LoginResponseProps> => {
    const resGG = await get({ url: `/uaa/social/login/google`, data: { code } })
    if (!resGG.error && resGG.status !== false) {
        const token: TokenProps = {
            ...resGG,
            loginBy: "google",
            token: `${resGG.token_type} ${resGG.access_token}`
        }
        const resAccountCurrent = await get({ url: `/accounts/current`, token: token.token });
        if (!resAccountCurrent.error) {
            setAcc({
                fullName: resAccountCurrent.fullName,
                username: resAccountCurrent.name
            });
            setAuth(token);
            if (window.navigator.userAgent.includes(" unitech ")) {
                dangKyThietBi(resAccountCurrent.name);
            }
            return {
                error: false,
                token: token,
                accountCurrent: resAccountCurrent
            };
        }
    }
    return {
        error: true,
        token: null
    };
}

export const loginWithZalo = async (code: string): Promise<LoginResponseProps> => {
    const resGG = await get({ url: `/uaa/social/login/zalo`, data: { code } })
    if (!resGG.error && resGG.status !== false) {
        const token: TokenProps = {
            ...resGG,
            loginBy: "zalo",
            token: `${resGG.token_type} ${resGG.access_token}`
        }
        const resAccountCurrent = await get({ url: `/accounts/current`, token: token.token });
        if (!resAccountCurrent.error) {
            setAcc({
                fullName: resAccountCurrent.fullName,
                username: resAccountCurrent.name
            });
            setAuth(token);
            if (window.navigator.userAgent.includes(" unitech ")) {
                dangKyThietBi(resAccountCurrent.name);
            }
            return {
                error: false,
                token: token,
                accountCurrent: resAccountCurrent
            };
        }
    }
    return {
        error: true,
        token: null
    };
}


export const loginWithEgov = async (code: string, session_state: string): Promise<LoginResponseProps> => {
    const resEGOV = await get({
        url: `/uaa/sso/complete`, data: { code, session_state }, headerOptions: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': "Basic YnJvd3Nlcjo="
        }
    })
    if (!resEGOV.error && resEGOV.status !== false) {
        const token: TokenProps = {
            ...resEGOV.result,
            loginBy: "egov",
            token: `${resEGOV.result.token_type} ${resEGOV.result.access_token}`
        }
        const resAccountCurrent = await get({ url: `/accounts/current`, token: token.token });
        if (!resAccountCurrent.error) {
            setAcc({
                fullName: resAccountCurrent.fullName,
                username: resAccountCurrent.name
            });
            setAuth(token);
            if (window.navigator.userAgent.includes(" unitech ")) {
                dangKyThietBi(resAccountCurrent.name);
            }
            return {
                error: false,
                token: token,
                accountCurrent: resAccountCurrent
            };
        }
    }
    return {
        error: true,
        token: null
    };
}
