import React, { Fragment, useState } from "react";
import { CheckboxProps, DatePicker, FormInstance, FormProps, Input, InputNumberProps, SelectProps, Switch, SwitchProps, TreeSelectProps } from "antd";
import { ReactNode } from "react";
import CommonForm from "./main";
import { FormItemType, FieldSelectProps, FormDataItemProps } from "./types";
import { InputProps } from "antd";
import { PickerDateProps, RangePickerDateProps } from "antd/lib/date-picker/generatePicker";
import { TextAreaProps } from "antd/lib/input";
import { MonthPickerProps } from "antd/lib/date-picker";
import { Moment } from "moment";
import { AntCheckbox, AntCurrency, AntDatePicker, AntInput, AntInputNumber, AntSelect, AntSwitch, AntTreeSelect, AntUpload } from "../antd";
import { InboxOutlined, LinkOutlined } from "@ant-design/icons";
import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import Editor from "@tinymce/tinymce-react"
import CommonEditor from "../c-editor";
import { AntCurrencyProps } from "../antd/currency";
const DateFormat = "DD/MM/YYYY";
const DateTimeFormat = "DD/MM/YYYY hh:mm:ss";
const MonthFormat = "MM/YYYY";
const YearFormat = "YYYY";

export default CommonForm;

export { DateFormat, DateTimeFormat, MonthFormat, YearFormat };
export const returnComponent = (
    type?: FormItemType | "map",
    props: InputProps | PickerDateProps<Moment> | RangePickerDateProps<Moment> | TextAreaProps | MonthPickerProps | CheckboxProps | SwitchProps | InputNumberProps | SelectProps<any> | UploadProps | TreeSelectProps<any> | AntCurrencyProps = {},
    placeholder?: string,
    name?: string,
    form?: FormInstance<any>,
    field?: FormDataItemProps
): ReactNode => {
    try {
        switch (type) {
            case "date":
                return <AntDatePicker format={DateFormat} placeholder={placeholder} allowClear {...props as PickerDateProps<Moment>} />;
            case "range-picker":
                return <AntDatePicker.RangePicker format={DateFormat} allowClear {...props as RangePickerDateProps<Moment>} />;
            case "datetime":
                return <AntDatePicker showTime format={DateTimeFormat} placeholder={placeholder} allowClear {...props as PickerDateProps<Moment>} />;
            case "month":
                return <AntDatePicker.MonthPicker format={MonthFormat} allowClear placeholder={placeholder} {...props as MonthPickerProps} />
            case "year":
                return <AntDatePicker.YearPicker format={YearFormat} allowClear placeholder={placeholder} {...props as PickerDateProps<Moment>} />
            case "textarea":
                return <AntInput.TextArea placeholder={placeholder} allowClear {...props as TextAreaProps} />
            case "checkbox":
                return <AntCheckbox {...props as CheckboxProps} />;
            case "switch":
                return <AntSwitch size="small" {...props as SwitchProps} />
            case "select":
                return <AntSelect placeholder={placeholder} allowClear {...props as SelectProps<any>} />
            case "select-tree":
                return <AntTreeSelect placeholder={placeholder} allowClear {...props as TreeSelectProps<any>} />
            case "password":
                return <AntInput.Password placeholder={placeholder} allowClear {...props as InputProps} />;
            case "number":
            case "int":
            case "float":
                return <AntInputNumber placeholder={placeholder} style={{ width: "100%", ...props.style }} {...props as InputNumberProps} />;
            case "upload":
                const values = name && form?.getFieldValue(name);
                return <AntUpload.Dragger
                    {...props as UploadProps}
                    beforeUpload={() => false}
                    fileList={values || []}
                    onChange={({ fileList }) => {
                    }}
                >
                    <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                    <p className="ant-upload-text">Chọn hoặc kéo thả vào đây để tải lên</p>
                </AntUpload.Dragger>
            case "currency":
                return <AntCurrency placeholder={placeholder} allowClear {...props as AntCurrencyProps} />
            case "editor":
                const editorValue = name && form?.getFieldValue(name) as string;
                return <div dangerouslySetInnerHTML={{ __html: editorValue || "" }} />
            default:
                return <AntInput placeholder={placeholder} allowClear {...props as InputProps} {...(type === "url" ? { prefix: <LinkOutlined /> } : {})} />;
        }
    }
    catch (e) { }
    return null;
}