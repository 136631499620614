import React, { useEffect, useState } from "react";
import { listenAttributeChanged } from "../fnc";
import LoadingBar from "./loading-bar";

const LoadingBar1 = () => {
    const [loading, setLoading] = useState<number>(0);
    useEffect(() => {
        listenAttributeChanged(document.body, () => {
            const loadingStr = document.body.getAttribute("loading");
            try {
                const loading: number = loadingStr && !isNaN(parseInt(loadingStr, 0)) ? parseInt(loadingStr, 0) : 0;
                setLoading(loading);
            }
            catch (e) { }
        })
    }, [])
    return <LoadingBar
        show={loading > 0}
    // color="#fff"
    />
}
export default LoadingBar1;