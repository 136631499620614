import { HOST_NAME, post, get, put, ResponseProps, del } from "../.fetch";
import { DataFormItem, FormProps } from "./data-form";



/**
 *  FetchAll
 */

export interface dangKyThietBi {
    deviceId?: any;
    deviceOS?: any;
    username?: String

}

export interface huyDangKy {
    username?: String

}


export const dangKyThietBiRequest = async (data?: dangKyThietBi) => await post({
    url: "/headlessCms/api/public/dathangsanpham/dangkythietbi",
    data
})

export const huyDangKyThietBiRequest = async (data?: string) => await post({
    url: "/headlessCms/api/public/dathangsanpham/huydangkythietbi",
    data,
    isJsonParse: false
})






