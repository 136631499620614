import React, { Fragment } from "react";
import { getAuth, removeAuth, AntModal } from "../common"
import { startLoad, HOST_NAME, urlLogin, endLoad, PutProps, ResponseProps, e401 } from ".";
import freshToken from "./.refresh";

const put = async ({
    hostName,
    url,
    data,
    showLoading = true,
    token,
    auth = true,
    controller,
    showMsg = true,
    formData,
    loadingMessage
}: PutProps): Promise<ResponseProps> => {
    showLoading && startLoad(loadingMessage)
    const _token = token || getAuth().token;
    const remember = getAuth().remember;
    const response = await fetch(`${hostName || HOST_NAME}${url}`, {
        method: 'PUT',
        headers: {
            ...(formData ? {} : { ['Content-Type']: 'application/json' }),
            ...(auth && _token ? { 'Authorization': _token } : {})
        },
        signal: controller?.signal,
        body: formData ? formData : JSON.stringify(data || {}),
    })
    showLoading && endLoad();
    const _data = await response.json();
    switch (response.status) {
        case 200:
            return { error: false, statusCode: 200, ..._data };
        case 401:
            if (remember) {
                return await freshToken({ hostName, callback: ({ token }) => put({ hostName, url, data, showLoading, token, auth, controller, showMsg }) })
            }
            return await e401({ error: true, statusCode: response.status, ..._data });
            return new Promise(resolve => {
                AntModal.warning({
                    title: "Phiên làm việc đã kết thúc",
                    content: "Vui lòng đăng nhập lại để tiếp tục sử dụng",
                    onOk: () => {
                        removeAuth();
                        resolve({ error: true, statusCode: response.status, ..._data })
                    },
                    onCancel: () => {
                        removeAuth();
                        resolve({ error: true, statusCode: response.status, ..._data })
                    },
                    okText: <Fragment><i className="fa fa-sign-in mr-5" />Đăng nhập</Fragment>
                })
            })
        default:
            return { error: true, statusCode: response.status };
    }
}
export default put;