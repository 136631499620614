import React, { Fragment, FC } from "react";
import { LoadingBarProps } from "./";
import Loading from "./loading";
import "./style.css";

const LoadingBar: FC<LoadingBarProps> = ({ show, color }: LoadingBarProps) => {
	return <Fragment>
		<Loading
			show={show}
			color={color}
		/>
	</Fragment>
}

export default LoadingBar;