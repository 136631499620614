import { ActionProps } from "..";
export interface ItemProps {
    lat: number;
    lng: number;
}

const stateDefault: ItemProps = {
    lat: 14,
    lng: 108
}

const currentLocation = (state: ItemProps | null = null, action: ActionProps): ItemProps | null => {
    const type = action.type;
    switch (type) {
        case 'CURRENT_LOCATION':
            const location = action.payload as ItemProps;
            return location;
        default:
            return state;
    }
}

export default currentLocation;