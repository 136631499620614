import React, { Fragment } from "react";
import { AntModal, getAuth, removeAuth } from "../common"
import { stringify } from "qs"
import { startLoad, GetProps, HOST_NAME, endLoad, ResponseProps, urlLogin, e401 } from ".";
import freshToken from "./.refresh";

const get = async ({
    hostName,
    url,
    data,
    showLoading = true,
    token,
    auth = true,
    controller,
    showMsg = true,
    loadingMessage,
    showModal401 = true,
    headerOptions
}: GetProps): Promise<ResponseProps> => {
    const _token = token || getAuth().token;
    const remember = getAuth().remember;
    showLoading && startLoad(loadingMessage);
    const response = await fetch(`${hostName || HOST_NAME}${url}?${stringify(data || {})}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...(auth && _token ? { 'Authorization': _token } : {}),
            ...(headerOptions || {}),
        },
        signal: controller?.signal
    })
        .catch(e => {
            showLoading && endLoad();
            const res: Promise<ResponseProps> = new Promise(resolve => resolve({ error: true, statusCode: 0 }));
            return {
                json: () => res,
                status: 0
            }
        })


    try {
        const _data = await response.json();
        showLoading && endLoad();
        switch (response.status) {
            case 200:
                return { error: false, statusCode: 200, ..._data };
            case 401:
                if (showModal401) {
                    if (remember) {
                        return await freshToken({ hostName, callback: ({ token }) => get({ hostName, url, data, showLoading, token, auth, controller, showMsg }) })
                    }
                    return await e401({ error: true, statusCode: response.status, ..._data });
                    // return new Promise(resolve => {
                    //     AntModal.warning({
                    //         title: "Phiên làm việc đã kết thúc",
                    //         content: "Vui lòng đăng nhập lại để tiếp tục sử dụng",
                    //         onOk: () => {
                    //             removeAuth();
                    //             resolve({ error: true, statusCode: response.status, ..._data })
                    //         },
                    //         onCancel: () => {
                    //             removeAuth();
                    //             resolve({ error: true, statusCode: response.status, ..._data })
                    //         },
                    //         okText: <Fragment><i className="fa fa-sign-in mr-5" />Đăng nhập</Fragment>
                    //     })
                    // })
                }
                else {
                    return { ..._data, error: true, statusCode: response.status, status: false };
                }
            default:
                return { ..._data, error: true, statusCode: response.status, status: false };
        }
    } catch (e) {
        showLoading && endLoad();
        return new Promise(resolve => resolve({ error: true, statusCode: 0 }));
    };
}
export default get;