import React from "react";
import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";

const createRoutineMachineLayer = ({ from, to, startCallback, successCallback, errorCallback, routeSelectedCallback }) => {

    const instance = L.Routing
        .control({
            waypoints: [
                L.latLng(from[0], from[1]),
                L.latLng(to[0], to[1])
            ],
            lineOptions: {
                styles: [{ color: "#1384ea", weight: 4 }]
            },
            fitSelectedRoutes: true,
            autoRoute: true,
            routeWhileDragging: false,
            showAlternatives: false,
            useZoomParameter: false,
        })
        .on("routingstart", (e) => {
            startCallback && typeof startCallback === "function" && startCallback(e)
        })
        .on("routesfound", (e) => {
            successCallback && typeof successCallback === "function" && successCallback(e)
        })
        .on("routingerror", (e) => {
            errorCallback && typeof errorCallback === "function" && errorCallback(e)
        })
        .on("routeselected", e => {
            const { summary, instructions } = e.target._selectedRoute;
            routeSelectedCallback && routeSelectedCallback({ summary, instructions });
        });

    return instance;
};

const RoutingMachine = createControlComponent(createRoutineMachineLayer);

export default (props) => {
    return <ErrorBoundary>
        <RoutingMachine {...props} />
    </ErrorBoundary>
};

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }
    render() {
        if (this.state.hasError) {
            return null;
        }
        return this.props.children;
    }
}