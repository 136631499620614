import { AutoCompleteProps, InputNumberProps, InputProps } from "antd";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { AntInput } from "..";
import { AntAutoComplete } from "./"

export interface AntCurrencyProps extends Omit<AutoCompleteProps, "value" | "onChange" | "placeholder"> {
    value?: number;
    onChange?: (value: number | null) => void;
    placeholder?: string;
    prefix?: ReactNode
}
const AntCurrency: FC<AntCurrencyProps> = (props) => {
    const [value, setValue] = useState<number | null>(0);
    useEffect(() => {
        if (props.value && isNaN(Number(`${props.value || ""}`.replace(/\$\s?|(,*)/g, '')))) {
            setValue(Number(`${props.value || ""}`.replace(/\$\s?|(,*)/g, '')));
        }
    }, [props.value])
    return <AntAutoComplete
        {...props}
        placeholder={undefined}
        style={{ ...(props.style || {}) }}
        onChange={(value) => {
            const res = Number(`${value || ""}`.replace(/\$\s?|(,*)/g, ''));
            if (isNaN(res)) {
                setValue(null);
                props.onChange && props.onChange(null);
            }
            else if (res >= 0 && Number.MAX_SAFE_INTEGER >= res) {
                setValue(res);
                props.onChange && props.onChange(res);
            }
        }}
        value={`${value || ""}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        options={
            value ?
                [
                    { value: value * 10, label: `${value * 10}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','), show: value * 10 <= Number.MAX_SAFE_INTEGER },
                    { value: value * 100, label: `${value * 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','), show: value * 100 <= Number.MAX_SAFE_INTEGER },
                    { value: value * 1000, label: `${value * 1000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','), show: value * 1000 <= Number.MAX_SAFE_INTEGER },
                    { value: value * 10000, label: `${value * 10000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','), show: value * 10000 <= Number.MAX_SAFE_INTEGER },
                    { value: value * 100000, label: `${value * 100000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','), show: value * 100000 <= Number.MAX_SAFE_INTEGER }
                ].filter(item => item.show) :
                [
                    { value: 1000000, label: "1,000,000" },
                    { value: 2000000, label: "2,000,000" },
                    { value: 5000000, label: "5,000,000" },
                    { value: 10000000, label: "10,000,000" },
                    { value: 20000000, label: "20,000,000" },
                ]
        }
    >
        <AntInput prefix={props?.prefix} placeholder={props?.placeholder} allowClear />
    </AntAutoComplete>
}

export default AntCurrency;
