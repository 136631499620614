
declare type KeyGetterCallback = (item1: any, item2: any) => boolean
const unique = (arr: Array<any>, keyGetter: KeyGetterCallback) => {
    var newArr = []
    for (var i = 0; i < arr.length; i++) {
        if (newArr.findIndex(n => keyGetter(n, arr[i])) === -1) {
            newArr.push(arr[i])
        }
    }
    return newArr
}

export default unique;