import {
  compose,
  createStore as reduxCreateStore,
  applyMiddleware,
  Reducer,
  CombinedState,
  combineReducers
} from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import auth, { AuthState } from "./auth";
import config, { ConfigProps } from "./config"
import data, { DataState } from "./data";
import mapData, { ObjectMapDataState } from "./mapData";
import pages, { DataState as PageDataState } from "./pages";
import redirect, { DataState as RedirectDataState } from "./redirect";
import dataFormbuider from './data-form-buider'
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export interface ActionProps {
  type: string;
  payload: any;
}

const composeEnhancer =
  window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;
const logger = createLogger({
  collapsed: true,
  predicate: () => process.env.NODE_ENV === `development`, // eslint-disable-line no-unused-vars
});

export const createStore = (reducer: Reducer<CombinedState<RootState>>) =>
  reduxCreateStore(
    reducer,
    process.env.NODE_ENV === `development`
      ? composeEnhancer(applyMiddleware(thunk, logger))
      : applyMiddleware(thunk, logger)
  );

export default combineReducers({
  auth,
  config,
  data,
  mapData,
  pages,
  redirect,
  dataFormbuider
}) as Reducer<CombinedState<RootState>>;

export interface RootState {
  auth: AuthState;
  config: ConfigProps,
  data: DataState[];
  mapData: ObjectMapDataState[];
  pages: PageDataState[],
  redirect: RedirectDataState[],
  dataFormbuider: any
}
