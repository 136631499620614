import React, { CSSProperties, FC, Fragment, useEffect, useRef, useState } from "react";
import { Carousel, CarouselProps } from "antd";

declare type SetOnCallback = (input: any) => any
declare type SetOnProps = any[] | SetOnCallback;
interface UseCarouselProps {
    onNext: () => void;
    onPrev: () => void;
    goTo: (i: number) => void;
    setOnNext: (input: SetOnProps) => void;
    setOnPrev: (input: SetOnProps) => void;
    setGoTo: (input: SetOnProps) => void;
}
export const useCarousel = (): UseCarouselProps => {
    const [onNext, setOnNext] = useState<any>();
    const [onPrev, setOnPrev] = useState<any>();
    const [goTo, setGoTo] = useState<any>();
    return {
        onNext: () => { onNext && typeof onNext === "function" && onNext() },
        onPrev: () => { onPrev && typeof onPrev === "function" && onPrev() },
        goTo: (i: number) => { goTo && typeof goTo === "function" && goTo(i) },
        setOnNext,
        setOnPrev,
        setGoTo
    }
}

interface IProps extends CarouselProps {
    ref?: any
    useCarousel?: UseCarouselProps;
    showArrows?: boolean
}
const AntCarousel: FC<IProps> = (props) => {
    const cRef: any = useRef(null);
    const carouselRef: any = props.ref || cRef;
    const _c = useCarousel();
    const _useCarousel = props.useCarousel || _c;
    useEffect(() => {
        _useCarousel.setOnNext(() => {
            return carouselRef?.current?.next
        });
        _useCarousel.setOnPrev(() => {
            return carouselRef?.current?.prev
        });
        _useCarousel.setGoTo(() => {
            return carouselRef?.current?.goTo
        });
    }, [carouselRef?.current, _useCarousel])
    return <div className="ant-carousel-custom-container">
        {props.showArrows && <Fragment>
            <span onClick={_useCarousel.onPrev} className="ant-carousel-custom-btn ant-carousel-custom-btn-left">
                <i className="fa fa-angle-left" style={{ cursor: "pointer", fontSize: 50 }} />
            </span>
            <span onClick={_useCarousel.onNext} className="ant-carousel-custom-btn ant-carousel-custom-btn-right">
                <i className="fa fa-angle-right" style={{ cursor: "pointer", fontSize: 50 }} />
            </span>
        </Fragment>}
        <Carousel ref={carouselRef} {...props} />
    </div>
}
AntCarousel.defaultProps = {
    showArrows: true
}
export default AntCarousel;