import { Base64 } from '../common';
import { get, post } from './../.fetch';
import { DataState } from "./../reducers/pages"
const apiUrl: string = '/headlessCms/api/public/getByUrl/';
const api: string = '/headlessCms/api/public/getPage/';
const apiAllPage: string = '/headlessCms/api/public/getAllPageChuaXoa';

interface PageProps {
    id: string;
    title: string;
    description?: string;
    url: string;
    publish?: boolean; // true : cho phép tất cả user | false : chỉ được phân quyền => 403
    status?: boolean; // false : 404
    frameUrl?: string;
    layout: string;
    parentId: number;
    sort: number;
    meta?: string;
}
// Builder option extends component
interface BuilderOptionProps {
    [key: string]: any
}
export interface PageBuilderOptionProps {
    id: number;
    optionId: string;//extends option id
    sort: number;
    buildOption: BuilderOptionProps;
}
export interface PageBuilderContentProps {
    id: number;
    page: PageProps;
    pageBuilderOption: PageBuilderOptionProps[]

}
export interface ItemProps {
    page: PageProps;
    pageBuilderContent: PageBuilderContentProps[];
}
interface FindPageByUrlInputProps {
    url: string;
    auth?: boolean
}
interface FindPageByIdInputProps {
    id: number
}
export const findPageByUrl = async ({ url, auth }: FindPageByUrlInputProps): Promise<ItemProps | null> => {
    const res = await get({
        url: `${apiUrl}${Base64.encode(url)}`,
        auth
    })
    if (res && res.status && res.result) {
        return res.result as ItemProps;
    }
    return null;
}
export const findPageById = async ({ id }: FindPageByIdInputProps): Promise<ItemProps | null> => {
    const res = await get({
        auth: false,
        url: `${api}${id}`,
    })
    if (res && res.status && res.result) {
        return res.result as ItemProps;
    }
    return null;
}

export const getAllPage = async (): Promise<DataState[]> => {
    const res = await get({
        url: apiAllPage,
        auth: false
    })
    if (res && res.status && res.result) {
        return res.result as DataState[];
    }
    return [];
}