import React, { Fragment } from "react";
import { AntModal, getAuth, removeAuth } from "../common"
import { stringify } from "qs"
import { Modal } from "antd";
import { startLoad, DownloadProps, HOST_NAME, urlLogin, endLoad } from ".";
import freshToken from "./.refresh";

function download({
    hostName,
    url,
    data,
    showLoading = true,
    token,
    auth = true,
    controller,
    showMsg = true,
    loadingMessage,
    responseType,
    method = "get"
}: DownloadProps): Promise<any> {
    showLoading && startLoad(loadingMessage)
    const _token = token || getAuth().token;
    let strFileName: string = "", type: string = "";
    const remember = getAuth().remember;
    return fetch(`${hostName || HOST_NAME}${url}?${method === "get" ? stringify(data || {}) : ""}`, {
        method: method === "post" ? "POST" : 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...(auth && _token ? { 'Authorization': _token } : {})
        },
        signal: controller?.signal,
        body: method === "post" ? JSON.stringify(data || {}) : undefined
    })
        .then((response: any) => {
            showLoading && endLoad();
            switch (response.status) {
                case 200:
                    // const arr = response.headers.get("Content-Disposition")?.split("filename=");
                    const contentDisposoton = response.headers.get("Content-Disposition");
                    const arr = contentDisposoton?.slice(contentDisposoton?.indexOf("filename="))?.split("filename=");
                    type = response.headers.get("Content-Type");
                    strFileName = arr && arr.length >= 2 ? arr[1] : "filename";
                    return response.blob();
                case 403:
                    AntModal.warning({
                        title: "Bạn không có quyền thực hiện thao tác này",
                        content: "Vui lòng liên hệ với quản trị viên",
                    })
                    return false;
                case 401:
                    if (remember) {
                        return freshToken({ hostName, callback: ({ token }) => download({ hostName, url, data, showLoading, token, auth, controller, showMsg }) })
                    }
                    return new Promise(resolve => {
                        AntModal.warning({
                            title: "Phiên làm việc đã kết thúc",
                            content: "Vui lòng đăng nhập lại để tiếp tục sử dụng",
                            onOk: () => {
                                removeAuth();
                                resolve({ error: true, statusCode: response.status })
                            },
                            onCancel: () => {
                                removeAuth();
                                resolve({ error: true, statusCode: response.status })
                            },
                            okText: <Fragment><i className="fa fa-sign-in mr-5" />Đăng nhập</Fragment>
                        })
                    })
                default:
                    return { error: true, statusCode: response.status };
            }
        })
        .then(blob => {
            if (blob) {
                const url = window.URL.createObjectURL(new Blob([blob], { type }));
                if (responseType !== "fileInfo") {
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = strFileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    link.remove();
                }
                return {
                    error: false, statusCode: 200, file: {
                        url,
                        type: type,
                        name: strFileName
                    }
                };
            }
        })
};
export default download;