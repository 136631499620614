import { ActionProps as CActionProps } from "./../"
import { getAuth, uuid as _uuid } from "../../common";
import { removeAuth, TokenProps } from "../../common/c-auth";
import { AccountProps } from "./../../.api/auth"
export interface AuthState {
    uuid: string;
    invalid: boolean;
    token?: TokenProps;
    accountCurrent?: AccountProps
}
const auth = getAuth();
const uuid = _uuid();
const stateDefault: AuthState = {
    uuid,
    invalid: auth.token ? false : true,
    token: auth
}
interface ActionProps extends CActionProps {
    callback?: (input: { current?: AccountProps, data: AuthState, callback?: () => void }) => void
}

export default (state: AuthState = stateDefault, action: ActionProps): AuthState => {
    const type = action.type;
    const payload = action.payload as {
        accountCurrent?: any;
        token?: TokenProps
    };
    const { callback } = action;
    switch (type) {
        case "LOGIN_SUCCESS":
            const LOGIN_SUCCESS_DATA = { invalid: false, token: payload.token, accountCurrent: payload.accountCurrent, uuid };
            callback && callback({ data: LOGIN_SUCCESS_DATA });
            return LOGIN_SUCCESS_DATA;
        case "LOGIN_CHECKED":
            const LOGIN_CHECKED_DATA = { ...state, accountCurrent: payload.accountCurrent, uuid };
            callback && callback({ data: LOGIN_CHECKED_DATA });
            return LOGIN_CHECKED_DATA;
        case "LOGOUT":
            const current = state.accountCurrent;
            removeAuth();
            const LOGOUT_DATA = { invalid: true, uuid }
            callback && callback({
                current,
                data: LOGOUT_DATA,
                callback: () => removeAuth()
            });
            return LOGOUT_DATA;
        case "ACCOUNT_CURRENT_UPDATE":
            return { ...state, accountCurrent: payload.accountCurrent };
        case "AUTH_CHANGED":
            return { ...action.payload, uuid };
        default:
            return state;
    }
} 