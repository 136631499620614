import { ActionProps } from ".."
export interface DataState {
    from: string;
    to: string
}

export default (state: DataState[] = [], action: ActionProps): DataState[] => {
    const type = action.type;

    switch (type) {
        case "REDIRECT_LOAD_DATA":
            const payloadLoadData = action.payload as DataState[];
            return payloadLoadData;
        case "REDIRECT_ADD_DATA":
            const payloadAddData = action.payload as DataState;
            return [
                ...state,
                payloadAddData
            ];
        default:
            return state;
    }
} 