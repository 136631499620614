import { ActionProps } from "..";

export interface MapProps {
    name?: string;
    lat?: number;
    lng?: number;
    formId?: number;
}

export interface FileProps {
    nameField?: string;
    value?: string;
    id?: number;
}

export interface FileSelectedProps {
    files?: Array<FileProps>;
    allowMulti?: boolean;
}

export interface FormBuilderProps {
    name?: string;
    id?: number;
    key?: number;
    nameDatabase?: string;
    isFolder?: boolean;
    manageVersion?: boolean;
}

export interface FormFieldProps {
    id?: number;
    name?: string;
    key: number;
    description?: string;
    fieldOptionText?: string;
}

export interface listStoreProps {
    key?: number;
    value?: string;
}

export interface FormBuildersProps {
    form: FormBuilderProps;
    formFields: Array<FormFieldProps>;
}

export interface DataFormBuilderProps {
    name?: string;
    id?: string;
    key: number;
    createdDate?: string;
    createdBy?: string;
}

export interface FormWithConfigProps {
    forms?: Array<FormBuildersProps>;
    listStoreProps?: Array<listStoreProps>;
}

export interface DataRefProps {
    formId?: number;
    formFieldId?: string;
    dataRef?: any[];
}

const stateFormDataDefault: Array<DataFormBuilderProps> = [];
const stateFormDefault: FormWithConfigProps = {};
const stateFormFieldDefault: Array<FormFieldProps> = [];
const stateMapDefault: Array<MapProps> = [];
const stateFileDefault: FileSelectedProps = {};
const stateFormBuilderDefault: FormBuilderProps = {};
const stateDataRefDefault: Array<DataRefProps> = [];

export const dataRef = (
    state: DataRefProps[] = stateDataRefDefault,
    action: ActionProps
): DataRefProps[] => {
    const type = action.type;
    switch (type) {
        case "ADD_DATA_REF":
            const dataAdd = action.payload as DataRefProps;
            const dataRefExit = state.find(el => el.formFieldId === dataAdd.formFieldId && (el.formId + "") === (dataAdd.formId + ""));
            if (!dataRefExit) {
                return [...state, ...[dataAdd]];
            } else {
                return state.map(el => el.formFieldId === dataAdd.formFieldId && (el.formId + "") === (dataAdd.formId + "") ? { ...el, dataRef: dataAdd.dataRef } : el)
            }
        case "CLEAR_DATA_REF":
            return state = []
        case "DELETE_DATA_REF":
            const dataDelete = action.payload as DataRefProps;
            return state.filter(item => (item.formId + "") !== (dataDelete.formId + "")) as DataRefProps[];
        default:
            return state;
    }
};

export const map = (
    state: MapProps[] = stateMapDefault,
    action: ActionProps
): MapProps[] => {
    const type = action.type;
    switch (type) {
        case "MAP_SELECTED":
            const maps = action.payload as MapProps[];
            return maps;
        case "MAP_DELETE_BY_FORMID":
            const _p = action.payload as { formId: number };
            return state.filter((item: MapProps) => item.formId + "" !== _p.formId + "") as MapProps[];
        default:
            return state;
    }
};

export const formBuilders = (
    state: FormWithConfigProps = stateFormDefault,
    action: ActionProps
): FormWithConfigProps => {
    const type = action.type;
    switch (type) {
        case "FORM_BUILDER_LOAD":
            const forms = action.payload as FormWithConfigProps;
            return forms;
        default:
            return state;
    }
};

export const formBuilder = (
    state: FormBuilderProps = stateFormBuilderDefault,
    action: ActionProps
): FormBuilderProps => {
    const type = action.type;
    switch (type) {
        case "FORM_BUILDER_SELECTED":
            return action.payload as FormBuilderProps;
        default:
            return state;
    }
};

export const formFields = (
    state: FormFieldProps[] = stateFormFieldDefault,
    action: ActionProps
): FormFieldProps[] => {
    const type = action.type;
    switch (type) {
        case "FORM_FIELD_BUILDER_LOAD":
            const forms = action.payload as FormFieldProps[];
            return forms;
        default:
            return state;
    }
};

export const fileSelected = (
    state: FileSelectedProps = stateFileDefault,
    action: ActionProps
): FileSelectedProps => {
    const type = action.type;
    switch (type) {
        case "GET_ID_FILE_SELECT_UPLOAD":
            const file = action.payload as FileSelectedProps;
            return file;
        default:
            return state;
    }
};

const dataFormBuilders = (
    state: DataFormBuilderProps[] = stateFormDataDefault,
    action: ActionProps
): DataFormBuilderProps[] => {
    const type = action.type;
    switch (type) {
        default:
            return state;
    }
};
export default dataFormBuilders;
