import * as antd from "antd";
import * as AntIcon from '@ant-design/icons';
import AntAlert from "./alert";
import AntCarousel, { useCarousel as useAntCarousel } from "./carousel";
import { DateFormat } from "../c-form";
import AntCurrency from "./currency";
// import AntImage from "./image";
import imgFallback from "./img-fallback.png";
import noimg from "./no-image.jpg";

const
    AntTable = antd.Table,
    AntTypography = antd.Typography,
    AntLayout = antd.Layout,
    AntAffix = antd.Affix,
    AntBreadcrumb = antd.Breadcrumb,
    AntDropdown = antd.Dropdown,
    AntMenu = antd.Menu,
    AntPagination = antd.Pagination,
    AntPageHeader = antd.PageHeader,
    AntSteps = antd.Steps,
    AntAutoComplete = antd.AutoComplete,
    AntCheckbox = antd.Checkbox,
    AntCascader = antd.Cascader,
    AntDatePicker = antd.DatePicker,
    AntForm = antd.Form,
    AntInputNumber = antd.InputNumber,
    AntMentions = antd.Mentions,
    AntRate = antd.Rate,
    AntRadio = antd.Radio,
    AntSwitch = antd.Switch,
    AntSlider = antd.Slider,
    AntSelect = antd.Select,
    AntTreeSelect = antd.TreeSelect,
    AntTransfer = antd.Transfer,
    AntTimePicker = antd.TimePicker,
    AntUpload = antd.Upload,
    AntAvatar = antd.Avatar,
    AntBadge = antd.Badge,
    AntComment = antd.Comment,
    AntCollapse = antd.Collapse,
    // AntCarousel = antd.Carousel,
    AntCard = antd.Card,
    AntCalendar = antd.Calendar,
    AntDescriptions = antd.Descriptions,
    AntEmpty = antd.Empty,
    AntList = antd.List,
    AntPopover = antd.Popover,
    AntStatistic = antd.Statistic,
    AntTree = antd.Tree,
    AntTooltip = antd.Tooltip,
    AntTimeline = antd.Timeline,
    AntTag = antd.Tag,
    AntTabs = antd.Tabs,
    AntMessage = antd.message,
    AntProgress = antd.Progress,
    AntPopconfirm = antd.Popconfirm,
    AntResult = antd.Result,
    AntSkeleton = antd.Skeleton,
    AntAnchor = antd.Anchor,
    AntBackTop = antd.BackTop,
    AntConfigProvider = antd.ConfigProvider,
    AntDivider = antd.Divider,
    AntInput = antd.Input,
    AntRow = antd.Row,
    AntCol = antd.Col,
    AntButton = antd.Button,
    AntDrawer = antd.Drawer,
    AntModal = antd.Modal,
    AntSpace = antd.Space,
    AntNotification = antd.notification,
    // AntAlert = antd.Alert,
    AntSpin = antd.Spin,
    AntImage = antd.Image;

AntDropdown.defaultProps = {
    ...AntDropdown.defaultProps,
    getPopupContainer: () => document.getElementById("layout") || document.body
};

AntTooltip.defaultProps = {
    ...AntTooltip.defaultProps,
    getPopupContainer: () => document.getElementById("layout") || document.body
};

AntDatePicker.defaultProps = {
    ...AntDatePicker.defaultProps,
    getPopupContainer: () => document.getElementById("layout") || document.body
};

AntAlert.defaultProps = {
    ...AntAlert.defaultProps,
    showIcon: true
}
AntTimePicker.defaultProps = {
    ...AntTimePicker.defaultProps,
    getPopupContainer: () => document.getElementById("layout") || document.body
}
AntAvatar.defaultProps = {
    src: "/static/img/avatar-default.png"
}
AntImage.defaultProps = {
    fallback: process.env.REACT_APP_URL_NO_IMAGE
}

export {
    AntTypography,
    AntLayout,
    AntButton,
    AntAffix,
    AntBreadcrumb,
    AntDropdown,
    AntMenu,
    AntPagination,
    AntPageHeader,
    AntSteps,
    AntAutoComplete,
    AntCheckbox,
    AntCascader,
    AntDatePicker,
    AntForm,
    AntInputNumber,
    AntMentions,
    AntRate,
    AntRadio,
    AntSwitch,
    AntSlider,
    AntSelect,
    AntTreeSelect,
    AntTransfer,
    AntTimePicker,
    AntUpload,
    AntAvatar,
    AntBadge,
    AntComment,
    AntCollapse,
    AntCarousel,
    AntCard,
    AntCalendar,
    AntDescriptions,
    AntEmpty,
    AntList,
    AntPopover,
    AntStatistic,
    AntTree,
    AntTooltip,
    AntTimeline,
    AntTag,
    AntTabs,
    AntTable,
    AntAlert,
    AntDrawer,
    AntModal,
    AntMessage,
    AntNotification,
    AntProgress,
    AntPopconfirm,
    AntResult,
    AntSpin,
    AntSkeleton,
    AntAnchor,
    AntBackTop,
    AntConfigProvider,
    AntDivider,
    AntInput,
    AntRow,
    AntCol,
    AntIcon,
    AntSpace,
    AntImage,
    useAntCarousel,
    AntCurrency
};