import React, { FC, Fragment, Suspense } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, Popconfirm, Skeleton, Tooltip, Button } from "antd";
import { ActionProps } from "./types";


const BtnAction: FC<ActionProps> = ({
    action_url,
    action_tooltip,
    action_confirm,
    action_dropdown,
    action_permission,
    action_hide,
    onClick,
    href,
    className,
    disabled,
    ...props
}) => {
    const history = useHistory();
    const allow = true;
    return !action_hide && allow ? <Fragment>
        <Suspense fallback={<Skeleton.Button />}>
            <Tooltip title={action_tooltip}>
                {
                    action_confirm ?
                        <Popconfirm
                            {...action_confirm}
                            disabled={disabled}
                            okText={action_confirm.okText || <Fragment><i className="fa fa-check i-btn-l" />Đồng ý</Fragment>}
                            cancelText={action_confirm.cancelText || <Fragment><i className="fa fa-times i-btn-l" />Huỷ</Fragment>}
                            onConfirm={(e) => {
                                action_confirm.onConfirm && action_confirm.onConfirm(e)
                                if (action_url) {
                                    e?.preventDefault();
                                    history.push(action_url);
                                }
                            }}
                        >
                            {
                                action_dropdown ? <Dropdown {...action_dropdown} disabled={disabled}>
                                    <Button
                                        className={`btn-action ${className || ""}`}
                                        href={action_url || href}
                                        disabled={disabled}
                                        {...props}
                                    />
                                </Dropdown> :
                                    <Button
                                        className={`btn-action ${className || ""}`}
                                        href={action_url || href}
                                        disabled={disabled}
                                        {...props}
                                    />
                            }
                        </Popconfirm> :
                        action_dropdown ? <Dropdown {...action_dropdown} disabled={disabled}>
                            <Button
                                className={`btn-action ${className || ""}`}
                                href={action_url || href}
                                disabled={disabled}
                                onClick={(e) => {
                                    onClick && onClick(e);
                                    if (action_url) {
                                        e.preventDefault();
                                        history.push(action_url);
                                    }
                                }}
                                {...props}
                            />
                        </Dropdown> :
                            <Button
                                className={`btn-action ${className || ""}`}
                                href={action_url || href}
                                disabled={disabled}
                                onClick={(e) => {
                                    onClick && onClick(e);
                                    if (action_url) {
                                        e.preventDefault();
                                        history.push(action_url);
                                    }
                                }}
                                {...props}
                            />
                }
            </Tooltip>
        </Suspense>
    </Fragment> : null
}

export default BtnAction;