import { setAuth, AntModal, TokenProps } from "../common";
import { stringify } from "qs";
import {
  startLoad,
  HOST_NAME,
  endLoad,
  LoginPostProps,
  ResponseLoginProps,
} from ".";
import SendMailActive from "./../.layout/modules/forms/SendMailActive";
import React, { Fragment } from "react";
export default async ({
  hostName,
  data,
  showLoading = true,
  controller,
}: LoginPostProps): Promise<ResponseLoginProps> => {
  const { username, password, remember } = data;
  showLoading && startLoad("Đang đăng nhập...");
  const response = await fetch(`${hostName || HOST_NAME}/uaa/oauth/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Basic YnJvd3Nlcjo=",
    },
    signal: controller?.signal,
    body: stringify({
      username,
      password,
      grant_type: "password",
    }),
  }).catch((e) => {
    showLoading && endLoad();
    const res: Promise<ResponseLoginProps> = new Promise((resolve) =>
      resolve({ error: true, statusCode: 0 })
    );
    return {
      json: () => res,
      status: 0,
    };
  });
  showLoading && endLoad();
  const _data = await response.json();
  const error = _data.error; // unauthorized | invalid_grant
  const error_description = _data.error_description; // CUSTOM_ACCOUNT_BLOCK |
  const rest_attempt_login = _data.rest_attempt_login; // num
  switch (response.status) {
    case 200:
      const _res: TokenProps = {
        ..._data,
        loginBy: "system",
        remember,
        token: `${_data.token_type} ${_data.access_token}`,
      };
      setAuth(_res);
      return { error: false, statusCode: 200, token: _res };
    case 400:
      if (
        error === "invalid_grant" &&
        rest_attempt_login &&
        !isNaN(rest_attempt_login)
      ) {
        const num = parseInt(_data.rest_attempt_login, 0);
        if (num <= 2) {
          AntModal.warning({
            content: `Nếu đăng nhập sai ${
              num + 1
            } lần nữa tài khoản của bạn sẽ bị khoá!`,
          });
        }
      }

      if (
        error === "invalid_grant" &&
        error_description === "User is disabled"
      ) {
        AntModal.warning({
          content: <SendMailActive />,
        });
      }

      return { error: true, statusCode: response.status };
    case 401:
      if (
        error === "unauthorized" &&
        error_description === "CUSTOM_ACCOUNT_BLOCK"
      ) {
        AntModal.error({
          content: `Tài khoản của bạn đã bị khoá. Vui lòng liên hệ với quản trị viên để biết thông tin!`,
        });
      }
      return { error: true, statusCode: response.status };
    default:
      return { error: true, statusCode: response.status };
  }
};
