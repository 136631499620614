import React, { useState, Fragment, FC, useEffect } from 'react';
import CropImage from "./Crop";
import { AntModal } from '../antd';
interface IProps {
    src?: string | null;
    visible?: boolean;
    onOk: (fileUrl?: string) => void;
    onCancel: () => void;
    aspect?: any
}
const CropModal: FC<IProps> = ({
    src,
    visible = true,
    onCancel,
    onOk,
    aspect = {
        x: null,
        y: null
    }
}) => {
    const [crop, setCrop] = useState({
        unit: '%',
        width: 0,
        aspect: !isNaN(aspect) ?
            parseFloat(`${aspect}`) :
            aspect && !isNaN(aspect.x) && !isNaN(aspect.y) ?
                aspect.x / aspect.y :
                undefined
    });
    useEffect(() => {
        if (visible) {
            setCrop({
                unit: "%",
                width: 0,
                aspect: !isNaN(aspect) ?
                    parseFloat(`${aspect}`) :
                    aspect && !isNaN(aspect.x) && !isNaN(aspect.y) ?
                        aspect.x / aspect.y :
                        undefined
            })
            setImageRef(null);
            setFileUrl(src);
        }
    }, [visible, aspect, src])
    const [imageRef, setImageRef] = useState(null);
    const [fileUrl, setFileUrl] = useState(src);
    return <Fragment>
        <AntModal
            title="Chỉnh sửa"
            style={{ top: 50 }}
            visible={visible}
            maskClosable={false}
            onOk={() => {
                onOk(fileUrl || undefined);
                onCancel();
            }}
            onCancel={onCancel}
            okText="Cập nhật"
            okButtonProps={{ icon: <i className="fa fa-save mr-5" /> }}
            cancelButtonProps={{ icon: <i className="fa fa-times mr-5" /> }}
        >
            <CropImage
                src={src}
                crop={crop}
                setCrop={setCrop}
                imageRef={imageRef}
                setImageRef={setImageRef}
                fileUrl={fileUrl}
                setFileUrl={setFileUrl}
            />
        </AntModal>
    </Fragment>
}

export default CropModal;
