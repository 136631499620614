import { Col, Form, Row, Button } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import React, { FC, Fragment, ReactNode, useEffect, useRef, useState } from "react";
import { returnComponent } from ".";
import { AntCol, AntRow, AntTabs } from "../antd";
import { FCMainProps, FormData } from "./types";

const Main: FC<FCMainProps> = ({
    data = [],
    children,
    initialValues,
    tabs,
    focusFirstField = true,
    scrollToFirstError = true,
    ...props
}) => {
    const ref = React.createRef<FormInstance>();
    const [form] = useForm();
    const _form = props.form || form;
    useEffect(() => {
        ref.current!.setFieldsValue(initialValues);
    }, [initialValues])

    useEffect(() => {
        if (focusFirstField && (data.length > 0 || tabs)) {
            const _fields = data.length > 0 ? data : (tabs?.data[0] ? tabs?.data[0].fields : []);
            const firstField = _fields.find(item => item.type !== "divider" && item.type !== "group" && !item.fieldProps?.disabled && item.name);
            const firstFieldName = firstField && firstField.type !== "divider" && firstField.type !== "group" ? firstField.name : null;
            if (firstFieldName) {
                const elm = document.getElementById(firstFieldName);
                elm?.focus();
            }
        }
    }, [focusFirstField, data])

    const [tabActive, setTabActive] = useState<string | undefined>(tabs && tabs.activeKey);

    const mapFields = (fields: FormData = []): ReactNode => {
        return fields.map((item, i) => {
            switch (item.type) {
                case "divider":
                    return <div className="ant-col ant-col-24" />;
                case "group":
                    return <AntCol span={item.col} key={i} className={`${item.colClassName || ""} mb-10`}>
                        <Form.Item
                            label={item.groupName}
                            rules={item.formItemProps?.rules}
                        >
                            {mapFields(item.fields || [])}
                        </Form.Item>
                    </AntCol>
                case "map":
                    return <AntCol span={item.col} key={i} className={`${item.colClassName || ""} mb-10`}>
                        <AntRow>
                            <AntCol span={12}>
                                <Form.Item
                                    style={{ marginBottom: 5 }}
                                    fieldKey={`${item.name}.lat`}
                                    name={[item.name, "lat"]}
                                    label="Lat"
                                >
                                    {returnComponent(
                                        "number",
                                        { ...(item.fieldProps || {}) },
                                        "Lats"
                                    )}
                                </Form.Item>
                            </AntCol>
                            <AntCol span={12}>
                                <Form.Item
                                    style={{ marginBottom: 5 }}
                                    fieldKey={`${item.name}.lat`}
                                    name={[item.name, "lng"]}
                                    label="Lon"
                                >
                                    {returnComponent(
                                        "number",
                                        { ...(item.fieldProps || {}) },
                                        "Lon"
                                    )}
                                </Form.Item>
                            </AntCol>
                        </AntRow>
                        <AntRow>
                            <AntCol span={24} className="px-10">
                                Đây là ảnh bản đồ
                            </AntCol>
                        </AntRow>
                    </AntCol>
                default:
                    const rules = item.formItemProps?.rules || [];
                    if (item.type === "email") {
                        rules.unshift({ type: "email", message: "Vui lòng nhập định dạng email." })
                    }
                    else if (item.type === "url") {
                        rules.unshift({ type: "email", message: "Vui lòng nhập định dạng email." })
                    }
                    return !item.hidden && <AntCol span={item.col} key={i} itemID={item.name} className={`${item.colClassName || ""}`}>
                        <Form.Item
                            style={{ marginBottom: 5 }}
                            {...item.formItemProps}
                            fieldKey={item.name}
                            name={item.name}
                            label={item.formItemProps?.label}
                            valuePropName={item.type === "checkbox" || item.type === "switch" ? "checked" : "value"}
                            rules={rules}
                        >
                            {returnComponent(
                                item.type,
                                { ...(item.fieldProps || {}) },
                                typeof item.formItemProps?.label === "string" ? item.formItemProps.label : undefined,
                                item.name,
                                _form,
                                item
                            )}
                        </Form.Item>
                    </AntCol >
            }
        })
    }
    return <Fragment>
        <Form
            ref={ref}
            labelCol={{ span: 8 }}
            {...props}
            initialValues={initialValues}
            form={_form}
            onFinishFailed={({ errorFields, values, outOfDate }) => {
                const e = errorFields.filter(item => item.name.length > 0)[0];
                if (e) {
                    const eName = e.name[0];
                    if (eName && typeof eName === "string") {
                        if (tabs) {
                            const tab = tabs.data.find(d => d.fields.find(item => item.type !== "divider" && item.type !== "group" && item.name === eName) ? true : false) as { key: string } | undefined;
                            if (tab?.key) {
                                tabs.onChange && tabs.onChange(tab.key);
                                setTabActive(tab.key);
                            }
                        }
                        const eElm = document.getElementById(eName);
                        if (eElm && scrollToFirstError) {
                            eElm.scrollIntoView();
                            eElm.focus();
                        }
                    }
                }
                props.onFinishFailed && props.onFinishFailed({ errorFields, values, outOfDate });
            }}
            scrollToFirstError={scrollToFirstError}
            onFinish={(values) => {
                props.onFinish && props.onFinish(values)
            }}
        >
            <AntRow>
                {mapFields(data)}
            </AntRow>
            {tabs && <AntRow>
                <AntCol span={24}>
                    <AntTabs
                        activeKey={tabActive}
                        onChange={(activeKey) => {
                            tabs.onChange && tabs.onChange(activeKey)
                            setTabActive(activeKey);
                        }}
                        className={`${tabs.className || ""} form-tabs`}
                        {...tabs}
                    >
                        {
                            (tabs.data || []).map((tab, i) => <AntTabs.TabPane key={i} {...tab}>
                                <AntRow>
                                    {mapFields(tab.fields || [])}
                                </AntRow>
                            </AntTabs.TabPane>)
                        }
                    </AntTabs>
                </AntCol>
            </AntRow>
            }
            <button type="submit" style={{ display: "none" }} />
            {children}
        </Form >
    </Fragment >
}
export default Main;