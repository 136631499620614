import React, { CSSProperties, FC } from "react";
import { AntCol, AntRow } from "../antd";

interface CommonLoadingInterface {
    fullScreenHeight?: boolean;
    style?: CSSProperties;
    className?: string
}
const CommonLoading: FC<CommonLoadingInterface> = ({ fullScreenHeight, style, className }) => {
    return <AntRow style={{ height: fullScreenHeight ? "100vh" : "50px", ...style }} justify="center" align="middle" className={className}>
        <AntCol span={24} style={{ textAlign: "center" }}>
            <div className="root-loader"><div /><div /><div /><div /></div>
        </AntCol>
    </AntRow>
}

export default CommonLoading;
CommonLoading.defaultProps = {
    fullScreenHeight: true,
    className: ""
}