import { ActionProps } from ".."
export interface DataState {
    id: number;
    url: string;
    publish: boolean;
    status: boolean;
}
const stateDefault: DataState[] = []

export default (state: DataState[] = stateDefault, action: ActionProps): DataState[] => {
    const type = action.type;

    switch (type) {
        case "PAGE_LOAD_DATA":
            const payloadLoadData = action.payload as DataState[];
            return payloadLoadData;
        case "PAGE_ADD_DATA":
            const payloadAddData = action.payload as DataState;
            return [
                ...state,
                payloadAddData
            ];
        default:
            return state;
    }
} 