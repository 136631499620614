import { get, post } from './../.fetch';
const api: string = '/headlessCms/api/public/cauHinh';

interface LocaleItemProps {
    name: string;
    id: string;
    default?: boolean;
}

export const getAllLocales = async (): Promise<LocaleItemProps[]> => {
    const res = await findByTenThamSo("Locales");

    let result: LocaleItemProps[] = [];
    try {
        if (res.status) {
            result = JSON.parse(res.result.giaTri) as LocaleItemProps[];
        }
    }
    catch (e) { };
    return result;
}

export const getTitlePage = async (): Promise<string> => {
    const res = await findByTenThamSo("TitlePage")
    if (res.status && res.result && res.result.giaTri) {
        return res.result.giaTri;
    }
    return "";
}

export const findByTenThamSo = async (tenThamSo: string) => await get({
    auth: false,
    url: `${api}/${tenThamSo}`,
})