import React, { FC } from "react";
import Qrcode from "./qrcode";

interface ImageSettingsProps {
    src: string;
    x?: number;
    y?: number;
    height?: number;
    width?: number;
    excavate?: boolean
}
interface QRCodeProps {
    value: string;
    renderAs?: "canvas" | "svg";
    size?: number;
    bgColor?: string;
    fgColor?: string;
    level?: "L" | "M" | "Q" | "H";
    includeMargin?: boolean;
    imageSettings?: ImageSettingsProps
}
const QRCode: FC<QRCodeProps> = (props) => {
    return <Qrcode {...props} />
}
QRCode.defaultProps = {
    level: "L",
    fgColor: "#ffffff",
    bgColor: "#000000",
    size: 100,
    renderAs: "canvas",
    includeMargin: false
}
export default QRCode;