export const titleCase = (str: string): string => str.split(" ").map(c => c.charAt(0).toUpperCase() + c.substr(1, c.length - 1)).join(" ");
export const idCase = (str: string): string => {
    const res = str.split(" ").map(c => c.charAt(0).toUpperCase() + c.substr(1, c.length - 1)).join("");
    return `${res.charAt(0).toLocaleLowerCase()}${res.substr(1, res.length - 1)}`.replaceAll(" ", "");
}
export const urlCase = (str: string): string => str.toLowerCase().trim().replaceAll(" ", "-");
declare type Type = "urlCase" | "titleCase" | "idCase";
const removeVietnameseTones = (str: string, type?: Type): string => {
    const res = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
        .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
        .replace(/ì|í|ị|ỉ|ĩ/g, "i")
        .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
        .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
        .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
        .replace(/đ/g, "d")
        .replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A")
        .replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E")
        .replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I")
        .replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O")
        .replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U")
        .replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y")
        .replace(/Đ/g, "D")
        // Some system encode vietnamese combining accent as individual utf-8 characters
        // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
        // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
        .replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "")
        // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
        .replace(/\u02C6|\u0306|\u031B/g, "")
        // Remove punctuations
        // Bỏ dấu câu, kí tự đặc biệt
        .replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ")
        // Remove extra spaces
        // Bỏ các khoảng trắng liền nhau
        .replace(/ + /g, " ")
        .trim();
    switch (type) {
        case "idCase":
            return idCase(res);
        case "urlCase":
            return urlCase(res);
        case "titleCase":
            return titleCase(res);
        default:
            return res;
    }
}



export default removeVietnameseTones;